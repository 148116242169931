import request from '@/utils/request'

export function setCommentConfig(data) {
    return request({
        url: '/api/app/autoreplyconfig/addAutoReplyConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function getCommentList(data) {
    return request({
        url: '/api/app/autoreplyconfig/autoreplyConfigList',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function addUser(data) {
    return request({
        url: '/api/app/autoreplyconfig/addAccountToAutoReplyConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function delUser(data) {
    return request({
        url: '/api/app/autoreplyconfig/removeAccountConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function addContent(data) {
    return request({
        url: '/api/app/autoreplyconfig/addReplyContentConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function delContent(data) {
    return request({
        url: '/api/app/autoreplyconfig/removeContentConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function delComment(data) {
    return request({
        url: '/api/app/autoreplyconfig/removeAutoReplyConfig',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}