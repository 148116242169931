<template>
    <div class="comment_set">
        <div class="comment_from">
            <div class="input_group">
                <div class="label">规则名称<span class="required">*</span></div>
                <div class="input_box">
                    <el-input
                        v-model="formData.configName"
                        placeholder="请输入规则名称"
                    ></el-input>
                </div>
            </div>
            <div class="input_group">
                <div class="label hd">
                    <div class="left">
                        关键词
                        <el-popover
                            placement="top-center"
                            width="200"
                            trigger="hover"
                            content="绑定用户发布的视频有人评论时，评论包含设置的任意关键字就会触发设定好的回复内容"
                        >
                            <i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                        <span class="required">*</span>
                    </div>
                    <div class="right">
                        <el-button
                            type="primary"
                            icon="plus"
                            size="mini"
                            @click="add_input('keywords')"
                            >添加关键词</el-button
                        >
                    </div>
                </div>
                <div class="btn"></div>
                <div
                    class="input_box"
                    v-for="(item, index) in formData.keywords"
                    :key="index"
                >
                    <div class="inputs">
                        <div class="input">
                            <el-input
                                v-model="item.value"
                                :placeholder="item.placeholder"
                            ></el-input>
                        </div>

                        <div
                            class="del_btn"
                            title="点击移除"
                            @click="del_row(index, 'keywords')"
                        >
                            <i class="el-icon-delete-solid"></i>
                        </div>

                        <div class="message">{{ item.message }}</div>
                    </div>
                </div>
            </div>

            <div class="input_group">
                <div class="label">
                    匹配模式
                    <el-popover
                        placement="top-center"
                        width="200"
                        trigger="hover"
                        content="部分匹配：当评论命中一个关键词时，会触发自动回复，完全匹配：当评论命中所有关键词时，触发自动回复"
                    >
                        <i class="el-icon-question" slot="reference"></i>
                    </el-popover>
                    <span class="required">*</span>
                </div>
                <div class="input_box">
                    <el-radio-group v-model="formData.matchModel" size="small">
                        <el-radio :label="1" border>部分匹配</el-radio>
                        <el-radio :label="2" border>完全匹配</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="input_group">
                <div class="label hd">
                    <div class="left">
                        回复内容
                        <el-popover
                            placement="top-center"
                            width="200"
                            trigger="hover"
                            content="当发布视频触收到评论触发了自动回复时，会自动回复以下设置的内容"
                        >
                            <i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                        <span class="required">*</span>
                    </div>
                    <div class="right">
                        <el-button
                            type="primary"
                            icon="plus"
                            size="mini"
                            @click="add_input('conteng')"
                            >增加回复内容</el-button
                        >
                    </div>
                </div>
                <div class="btn"></div>
                <div class="input_box" v-for="item in contents" :key="item.id">
                    <div class="inputs">
                        <div class="input">
                            <el-input
                                v-model="item.autoreplyContent"
                                readonly
                            ></el-input>
                        </div>
                        <div
                            class="del_btn"
                            title="点击删除"
                            @click="del_content(index, item.id)"
                        >
                            <i class="el-icon-delete-solid"></i>
                        </div>

                        <div class="message"></div>
                    </div>
                </div>
                <div
                    class="input_box"
                    v-for="(item, index) in formData.contengts"
                    :key="index"
                >
                    <div class="inputs">
                        <div class="input">
                            <el-input
                                v-model="item.value"
                                :placeholder="item.placeholder"
                            ></el-input>
                        </div>

                        <div
                            class="del_btn"
                            title="点击移除"
                            @click="del_row(index, 'contengts')"
                        >
                            <i class="el-icon-delete-solid"></i>
                        </div>

                        <div class="message">{{ item.message }}</div>
                    </div>
                </div>
            </div>
            <div class="input_group">
                <div class="label">
                    回复模式
                    <el-popover
                        placement="top-center"
                        width="200"
                        trigger="hover"
                        content="选择回复模式，触发自动回复时，回复一条，还是全部回复"
                    >
                        <i class="el-icon-question" slot="reference"></i>
                    </el-popover>
                    <span class="required">*</span>
                </div>
                <div class="input_box">
                    <el-radio-group v-model="formData.replyModel" size="small">
                        <el-radio :label="1" border>随机一条</el-radio>
                        <el-radio :label="2" border>全部回复</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="input_group">
                <div class="label">是否启动<span class="required">*</span></div>
                <div class="input_box">
                    <el-radio-group v-model="formData.status" size="small">
                        <el-radio label="0" border>暂不</el-radio>
                        <el-radio label="1" border>启动</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="input_group" v-if="accountConfigList">
                <div class="label">已选账号</div>
                <div class="input_box">
                    <el-tag
                        v-for="(item, index) in accountConfigList"
                        :key="item.id"
                        closable
                        type="warning"
                        @close="del_user(index, item.id)"
                    >
                        {{ item.authorizedUser.userNickname }}
                    </el-tag>
                </div>
            </div>
            <div class="input_group">
                <div class="label hd">
                    <div class="left">
                        选择账号
                        <el-popover
                            placement="top-center"
                            width="200"
                            trigger="hover"
                            content="需要触发自动回复的授权用户"
                        >
                            <i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                        <span class="required">*</span>
                    </div>
                    <div class="right">
                        <el-select
                            v-model="value"
                            size="mini"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入分组名"
                            @change="change_group"
                            :remote-method="remoteMethod"
                            :loading="loading"
                        >
                            <el-option
                                v-for="item in user_group"
                                :key="item.id"
                                :label="item.groupName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="user_box">
                    <div class="null_data" v-if="!users.length">
                        <el-empty description="没有可用账号"></el-empty>
                    </div>
                    <div class="users">
                        <el-checkbox-group v-model="select_user" size="small">
                            <el-checkbox
                                :label="item.id"
                                :name="item.id"
                                border
                                :disabled="item.disabled"
                                v-for="(item, index) in users"
                                :key="index"
                            >
                                <div class="ellipsis label_box">
                                    {{ item.userNickname }}
                                </div>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn_row">
            <el-button size="small">取消</el-button>
            <el-button size="small" type="primary" @click="saveConfig">
                <span v-if="formData.id">更新</span>
                <span v-else>保存</span>
            </el-button>
        </div>
    </div>
</template>

<script>
import {
    setCommentConfig,
    addContent,
    addUser,
    delContent,
    delUser,
} from "@/api/marketing";
import { groupUser, getAutoGruopList } from "@/api/auth";
export default {
    name: "CommentSet",
    props: {
        select_data: {
            default: "",
        },
    },
    watch: {
        select_data(val) {
            if (val) {
                this.toRayProp();
            }
        },
    },
    data() {
        return {
            formData: {
                //规则名称
                configName: "",
                //关键词列表
                keywords: [
                    {
                        value: "",
                        placeholder: "请输入2-6个字符的关键词",
                        required: true,
                        message: "关键词不能为空",
                    },
                ],
                matchModel: 1, //匹配模式
                replyModel: 1, //回复模式
                status: "0",
                // 回复列表
                contengts: [
                    {
                        value: "",
                        placeholder: "请输入2-20个字符的回复内容",
                        required: true,
                        message: "回复内容不能为空",
                    },
                ],
                // 使用账号
                users: [],
            },
            users: [],
            select_user: [],

            contents: [],
            config_data: {},
            accountConfigList: [],
            where: {
                id: "",
                current: 1,
                size: 30,
                "orders[0].asc": false,
                "orders[0].column": "update_time",
                platformType: "douyin",
            },
            group_where: {
                groupName: "",
                current: 1,
                size: 10,
                "orders[0].asc": false,
                "orders[0].column": "update_time",
                platformType: "douyin",
            },
            user_group: [],
            loading: false,
            value: "",
        };
    },

    mounted() {
        this.toRayProp();
    },
    created() {
        this.getUsers();
        this.getUserGroup();
    },
    methods: {
        getUsers() {
            let where = this.where;
            groupUser(where).then((res) => {
                if (res.code == 200) {
                    this.users = res.data.records;

                    this.setDisabled();
                }
            });
        },
        getUserGroup() {
            let where = this.group_where;
            this.loading = true;
            getAutoGruopList(where).then((res) => {
                if (res.code == 200) {
                    this.user_group = res.data.records;
                }
                this.loading = false;
            });
        },
        remoteMethod(keyWord) {
            this.group_where.groupName = keyWord;
            this.getUserGroup();
        },
        change_group(val) {
            this.where.id = val;
            this.getUsers();
        },
        add_input(val) {
            switch (val) {
                case "keywords":
                    var keyword = {
                        value: "",
                        placeholder: "请输入2-6个字符的关键词",
                        required: true,
                        message: "关键词不能为空",
                    };
                    this.formData.keywords.push(keyword);
                    break;
                case "conteng":
                    var content = {
                        value: "",
                        placeholder: "请输入2-20个字符的回复内容",
                        required: true,
                        message: "回复内容不能为空",
                    };
                    this.formData.contengts.push(content);
                    break;
                default:
                    break;
            }
        },
        del_row(index, key) {
            let data = this.formData[key];
            if (data.length > 1) {
                data.splice(index, 1);
            } else {
                this.$message.error("移除失败哦~最少有一条设置");
            }
        },
        del_content(index, id) {
            delContent({ contentConfigId: id }).then((res) => {
                if (res.code == 200) {
                    this.$message.success("删除成功");
                    this.contents.splice(index, 1);
                }
            });
        },
        saveConfig() {
            let submit_data = this.formData;
            /** 

            let keyword_arr = [];
            submit_data.keywords.map((item) => {
                keyword_arr.push(item.value);
            });
            console.log(keyword_arr);
            let keyword = JSON.stringify(keyword_arr);
            let content_arr = [];
            submit_data.contengts.map((item) => {
                content_arr.push(item.value);
            });
            
            let replyContent = JSON.stringify(content_arr);
            let authorizedUserId = JSON.stringify(this.select_user);
            let config_data = {
                accessTrigger: false,
                accountIdArray: authorizedUserId,
                autoreplyContentList: [
                    {
                        autoreplyContent: replyContent,
                        replyContentType: "text",
                    },
                ],
                configName: "222222",
                keyword,
                matchModel: 0,
                replyModel: 0,
                replyType: 1,
                status: 1,
            };*/
            let keyArr = [];
            submit_data.keywords.map((item) => {
                keyArr.push(item.value);
            });
            submit_data.keyWordArray = keyArr.toString();
            delete submit_data.keywords;
            // console.log(submit_data);
            let contengts = [];
            submit_data.contengts.map((item) => {
                if (item.value != "") {
                    let json = {
                        autoreplyContent: item.value,
                        replyContentType: "text",
                    };
                    contengts.push(json);
                }
            });
            let add_contents = JSON.stringify(contengts);
            delete submit_data.contengts;

            delete submit_data.users;
            // [{'autoreplyContent':'回复内容1','replyContentType':'text'},{'autoreplyContent':'回复内容2','replyContentType':'text'}]
            setCommentConfig(submit_data).then((res) => {
                if (res.code == 200) {
                    this.$message.success("保存配置成功");
                    this.$emit("addList", res.data);
                    addContent({
                        autoreplyContentJsonArray: add_contents,
                        autoreplyId: res.data.id,
                    }).then((add_res) => {
                        if (add_res.code == 200) {
                            this.$message.success("回复内容添加成功");
                        }
                    });
                    if (this.select_user.length) {
                        let users = this.select_user.toString();
                        let user_data = {
                            autoreplyConfigId: res.data.id,
                            authorizedUserIds: users,
                        };
                        addUser(user_data).then((add_res) => {
                            if (add_res.code == 200) {
                                this.$message.success("添加账号成功");
                            }
                        });
                    }
                }
            });
        },

        toRayProp() {
            let edit_data = JSON.parse(this.select_data);
            this.formData.configName = edit_data.configName;
            this.formData.matchModel = edit_data.matchModel;
            this.formData.replyModel = edit_data.replyModel;
            this.formData.status = edit_data.status;
            this.formData.id = edit_data.id;
            this.formData.keywords = [];
            this.contents = edit_data.autoreplyContentList;

            this.accountConfigList = edit_data.accountConfigList;

            let key_arr = [];
            if (edit_data.keyword) {
                let keywords = JSON.parse(edit_data.keyword);
                console.log(keywords);
                keywords.map((item) => {
                    let keyItem = {
                        value: "",
                        placeholder: "请输入2-6个字符的关键词",
                        required: true,
                        message: "关键词不能为空",
                    };
                    keyItem.value = item;
                    key_arr.push(keyItem);
                });
                this.formData.keywords = key_arr;
            } else {
                let keyItem = {
                    value: "",
                    placeholder: "请输入2-6个字符的关键词",
                    required: true,
                    message: "关键词不能为空",
                };
                this.formData.keywords.push(keyItem);
            }
        },
        setDisabled() {
            if (!this.accountConfigList) return;
            let users = this.users;
            users.forEach((item) => {
                let check_index = this.accountConfigList.findIndex(
                    (user) => user.authorizedUserId === item.id
                );
                if (check_index < 0) {
                    item.disabled = false;
                } else {
                    item.disabled = true;
                }
            });
            // this.accountConfigList.forEach((item) => {
            //     let check_index = users.findIndex((user) => {
            //         user.id === item.authorizedUserId;
            //     });
            //     if (check_index > -1) {
            //         // 已经绑定过了
            //         users[check_index].disabled = true;
            //     }
            // });
        },
        del_user(index, id) {
            delUser({ accountConfigId: id }).then((res) => {
                if (res.code == 200) {
                    this.$message.success("账号移除成功");
                    this.accountConfigList.splice(index, 1);
                    this.setDisabled();
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}
.label_box {
    display: inline-block;
    width: 75px;
    vertical-align: middle;
}
.comment_from {
    height: 100%;
    overflow: auto;
}
.required {
    color: #ff3838;
}
.label {
    height: 34px;
    line-height: 34px;
}
.hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input_group + .input_group {
    margin-top: 10px;
}
.input_group {
    .btn {
        margin-bottom: 10px;
    }
}
.inputs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .input {
        flex: 0 0 600px;
    }
    .del_btn {
        padding: 10px;
        cursor: pointer;
    }
    .message {
        color: #279aff;
    }
}
.user_box {
    padding: 8px;
    border: 1px solid #e8ecef;
    .users {
        height: 260px;
    }
}

.comment_set {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .comment_set {
        flex: 1;
        height: 0;
        overflow: auto;
    }
    .btn_row {
        padding: 30px 0;
        display: flex;
        justify-content: center;
    }
}
.input_box .el-tag + .el-tag {
    margin-left: 8px;
}
</style>