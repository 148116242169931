<template>
    <div class="hd">
        <div class="title">
            <div>智能营销</div>
            <div class="tis">
                点赞、关注、评论、私信等多场景智能营销触达，实时抖音获客、营销、用户运营的自动化、智能化。
            </div>
        </div>
        <div class="switch_box">
            <div class="switch_row">
                <div class="type_message">
                    <span class="solid"></span>
                    智能评论自动回复
                </div>
                <el-switch v-model="switch_val"> </el-switch>
            </div>
            <div class="switch_tis" :class="switch_val ? 'success' : 'error'">
                <div class="tis_hd">
                    <i class="iconfont icon-keshangxian"></i>
                    自动回复生效中
                </div>
                <div class="tis_main">
                    <div>
                        温馨提示：自己评论自己的视频，不会执行自动回复；如需测试请用他人账号评论
                    </div>
                    <div>
                        请不要使用含有诱导营销含义的词语，否则会被抖音屏蔽不展示哦~
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "marketing",

    props: {
        type: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            switch_val: true,
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.hd {
    background-color: #fff;
    padding: 12px 20px;
    border-radius: 6px;
}
.title {
    line-height: 28px;
    display: flex;
    .tis {
        margin-left: 20px;
        color: #8590b3;
        font-size: 12px;
    }
}
.switch_box {
    margin-top: 10px;
    .switch_row {
        display: flex;
        justify-content: space-between;
        padding: 12px 30px;
        background-color: #f5f6f8;
    }
    .type_message {
        color: #279aff;
        position: relative;
        display: flex;
        align-items: center;
        .solid {
            width: 4px;
            height: 20px;
            background-color: #279aff;
            margin-right: 10px;
        }
    }
}
.switch_tis {
    border-radius: 2px;
    padding: 14px 28px;
    margin-top: 12px;
    .tis_hd {
        color: #334681;
        display: flex;
        align-items: center;
        .icon-keshangxian {
            font-size: 20px;
            margin-right: 4px;
        }
    }
    .tis_main {
        color: #8597b2;
        font-size: 12px;
        padding-left: 24px;
    }
    &.success {
        background-color: #f1faf3;
        border: 1px solid #86e175;

        .icon-keshangxian {
            color: #86e175;
        }
    }
    &.error {
        background-color: rgba(255, 103, 103, 0.08);
        border: 1px solid #ff6767;
        .icon-keshangxian {
            color: #ff6767;
        }
    }
}
</style>