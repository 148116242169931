<template>
    <div class="comment">
        <marketing_hd />
        <div class="main">
            <div class="list_hd">
                <el-tabs v-model="active" @tab-click="tab_change">
                    <el-tab-pane label="自动回复规则" name="1"></el-tab-pane>
                    <!-- <el-tab-pane label="评论回复记录" name="2"></el-tab-pane> -->
                </el-tabs>
            </div>
            <div class="edit_row">
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    @click="show_comment = true"
                    >添加规则</el-button
                >
            </div>
            <div class="table_box">
                <el-table :data="list" style="width: 100%" size="small">
                    <el-table-column
                        prop="configName"
                        label="规则名称"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column prop="keyword" label="关键词">
                    </el-table-column>
                    <el-table-column prop="matchModel" label="匹配模式">
                        <template slot-scope="scope">
                            <el-tag size="mini" v-if="scope.row.matchModel == 1"
                                >部分匹配</el-tag
                            >
                            <el-tag
                                size="mini"
                                type="success"
                                v-else-if="scope.row.matchModel == 2"
                                >完全匹配</el-tag
                            >
                            <el-tag type="info" v-else>暂未设置</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="replyModel"
                        label="回复模式"
                        width="180"
                    >
                        <template slot-scope="scope">
                            <el-tag size="mini" v-if="scope.row.matchModel == 1"
                                >回复一条</el-tag
                            >
                            <el-tag
                                size="mini"
                                type="success"
                                v-else-if="scope.row.matchModel == 2"
                                >回复全部</el-tag
                            >
                            <el-tag type="info" v-else>暂未设置</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <el-tag size="mini" v-if="scope.row.status == 1"
                                >已启动</el-tag
                            >
                            <el-tag size="mini" type="danger" v-else
                                >未启动</el-tag
                            >
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="status" label="回复内容">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="chenck_content(scope.row)"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="使用账号">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="check_users(scope.row)"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="createTime" label="创建时间">
                        <template slot-scope="scope">
                            {{
                                moment(scope.row.createTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateTime" label="更新时间">
                        <template slot-scope="scope">
                            {{
                                moment(scope.row.updateTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        align="center"
                        label="操作"
                        width="140"
                    >
                        <template slot-scope="scope">
                            <el-button
                                @click="edit(scope.row)"
                                type="text"
                                size="small"
                                >修改规则</el-button
                            >
                            <!-- <el-button type="text" size="small">编辑</el-button> -->
                            <el-button
                                type="text"
                                size="small"
                                @click="delRow(scope)"
                                >删除规则</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page_box">
                    <el-pagination
                        small
                        background
                        layout="prev, pager, next"
                        :total="total"
                        @current-change="changePage"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-drawer
            title="新建规则"
            :visible.sync="show_comment"
            size="990px"
            direction="rtl"
            :before-close="handleClose"
        >
            <comment_set
                v-if="show_comment"
                ref="comment_set"
                @addList="addList"
                :select_data="select_data"
                :key="keys"
            />
        </el-drawer>
    </div>
</template>

<script>
import moment from "moment";
import { getCommentList, delComment } from "@/api/marketing";
import marketing_hd from "./components/marketing_hd.vue";
import comment_set from "./components/comment_set.vue";
export default {
    name: "comment",
    components: { marketing_hd, comment_set },
    data() {
        return {
            moment,
            keys: Math.random(),
            active: "1",
            show_comment: false,
            sys: {},
            where: {
                current: 1,
                size: 10,
                "orders[0].asc": false,
                "orders[0].column": "update_time",
            },
            total: 0,
            list: [],
            select_data: null,
        };
    },

    mounted() {},
    created() {
        this.getList();
    },

    methods: {
        tab_change(val) {
            console.log(val);
        },
        handleClose() {
            this.keys = Math.random();
            this.show_comment = false;
            this.select_data = null;
        },
        async getList() {
            let where = this.where;
            let data = await getCommentList(where);
            this.list = data.data.records;
            this.total = data.data.total;
        },
        changePage(current) {
            this.where.current = current;
            this.getList();
        },
        edit(data) {
            this.select_data = JSON.stringify(data);
            this.show_comment = true;
        },
        delRow(data) {
            this.$confirm(
                `确认要删除 ${data.row.configName} 规则吗？, 删除后不可恢复哦 ！`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    let index = data.$index;
                    let json = {
                        autoreplyConfigId: data.row.id,
                    };
                    delComment(json).then((res) => {
                        if (res.code == 200) {
                            this.$message.success("删除成功 !");
                            this.list.splice(index, 1);
                        }
                    });
                })
                .catch(() => {
                    console.log(1);
                });
        },
        addList(data) {
            let index = this.list.findIndex((item) => item.id === data.id);
            if (index < 0) {
                this.list.unshift(data);
                console.log("插入数据");
            } else {
                console.log("更新数据");
                this.getList();
            }

            this.show_comment = false;
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    margin-top: 20px;
}
.list_hd {
    height: 54px;
    line-height: 54px;
    padding: 0 12px;
    /deep/ .el-tabs__header {
        margin: 0;
    }
    /deep/ .el-tabs__nav {
        height: 54px;
    }
}
.edit_row {
    padding: 20px;
}
.page_box {
    padding: 10px 0;
}
</style>